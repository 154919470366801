import React from "react";
import { Layout } from "~/templates/Layout";
import { Link } from "gatsby";
import { colors } from "~/utils/colors";

const TITLE = "メール配信停止を受け付けました";
export const UnsubscribeThanks: React.FC = () => (
  <>
    <style jsx>{`
      .wrapper {
        background-color: ${colors.white};
        padding: 0 30px;
        margin: 30px auto 50px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 500px;
      }
      .title {
        font-size: 24px;
        margin: 0 0 20px;
      }
      .description {
        font-size: 14px;
        margin: 0 0 20px;
      }
      .toTopLink {
        display: flex;
      }
      .toTopLink :global(a) {
        font-size: 14px;
        text-decoration: underline;
      }
    `}</style>
    <Layout title={TITLE}>
      <div className="wrapper">
        <h1 className="title">{TITLE}</h1>
        <p className="description">
          メール配信停止を受け付けました。
          <br />
          お問い合わせありがとうございました。
        </p>
        <div className="toTopLink">
          <Link to="/">トップページへ戻る</Link>
        </div>
      </div>
    </Layout>
  </>
);
